




























































































import { Vue } from "vue-property-decorator";
import Component from "vue-class-component";

@Component
export default class PrivacyPolicyPage extends Vue {}
